html, body, #root, .MuiBox-root, .MuiGrid-item {
  height: 100%;
}

.MuiGrid-container {
  height: calc(100% - 64px);

  @media (max-width: 600px) {
    height: calc(100% - 56px);
  }
}

textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  padding: 15px;
  font-size: 18px;
  font-family: "Fira Code", monospace, "Courier New", Courier;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.viewer {
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiListItemText-primary {
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
}

.nonhover {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

a {
  color: #0645ad;
  text-decoration: none;
}

a:visited {
  color: #0b0080;
}

a:hover {
  color: #06e;
}

a:active {
  color: #faa700;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

::-moz-selection {
  background: rgba(255, 255, 0, 0.3);
  color: #000;
}

::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #000;
}

a::-moz-selection {
  background: rgba(255, 255, 0, 0.3);
  color: #0645ad;
}

a::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #0645ad;
}

p {
  margin: 1em 0;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #111;
  line-height: 1em;
}

h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.9em;
}

blockquote {
  color: #666666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}

hr {
  display: block;
  border: 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #eee;
  margin: 1em 0;
  padding: 0;
}

pre,
code,
kbd,
samp {
  color: #000;
  font-family: monospace, monospace;
  font-size: 0.98em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 2em;
}

li p:last-child {
  margin: 0;
}

dd {
  margin: 0 0 0 2em;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

@media only screen and (min-width: 480px) {
  body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  body {
    font-size: 12pt;
    max-width: 100%;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid black;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    padding-right: 1em;
    page-break-inside: avoid;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page :left {
    margin: 15mm 20mm 15mm 10mm;
  }

  @page :right {
    margin: 15mm 10mm 15mm 20mm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
